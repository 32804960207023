import { PageRouteProps } from "types";
import CareersPage from "components/app/Careers";

const pageRoute: PageRouteProps[] = [
  {
    name: "Careers",
    component: <CareersPage />,
    path: "/*",
  },
];

export default pageRoute;
