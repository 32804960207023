import { SmallCloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  useOutsideClick,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import "../../../../../assets/css/autocomplete.css";

const AtsAutoComplete = (FieldProps: any) => {
  const [isVisible, setVisible] = useState(false);
  const onSelect = (item: any) => {
    if (typeof FieldProps.onSelect === "function") {
      FieldProps.onSelect(item);
    }
  };

  const ref = useRef();

  const onClickOutside = () => {
    setVisible(false);
  };

  useOutsideClick({
    ref,
    handler: onClickOutside,
  });

  return (
    <Box ref={ref}>
      <InputGroup>
        <Input
          name={FieldProps.name}
          placeholder={FieldProps.placeholder}
          type={FieldProps.type}
          variant={FieldProps.variant}
          value={FieldProps.value}
          onFocus={FieldProps.onFocus}
          onChange={(e: any) => {
            if (typeof FieldProps.onChange === "function") {
              FieldProps.onChange(e);
            }
            if (e.target.value == "") {
              setVisible(false);
            }
          }}
          onKeyUp={(e: any) => {
            if (typeof FieldProps.onKeyUp === "function") {
              FieldProps.onKeyUp(e);
            }
            setVisible(true);
          }}
          onBlur={(e: any) => {
            if (typeof FieldProps.onBlur === "function") {
              FieldProps.onBlur(e);
            }
          }}
          borderRadius={["6px", "0"]}
          background="white"
          width={["100%", "300px"]}
          height={["40px", "60px"]}
        />

        {FieldProps.value && (
          <InputRightElement height={["40px", "60px"]}>
            <Box
              onClick={() => {
                FieldProps.setFieldValue(FieldProps.name, "");
                FieldProps.onSubmit({
                  ...FieldProps.values,
                  [FieldProps.name]: "",
                });
              }}
              cursor="pointer"
              color="primary.800"
            >
              <SmallCloseIcon />
            </Box>
          </InputRightElement>
        )}
      </InputGroup>

      {isVisible || FieldProps?.menuOpen ? (
        <Box className="option-container">
          {FieldProps?.options?.length > 0 ? (
            FieldProps?.options?.map((item: any, key: number) => {
              return (
                <Link
                  key={`atscomplete-${key}`}
                  onClick={() => {
                    onSelect(item);
                    setVisible(false);
                  }}
                  className="item"
                  onFocus={() => {
                    setVisible(true);
                  }}
                >
                  {item.label}
                </Link>
              );
            })
          ) : (
            <Box className="item">No result found</Box>
          )}
        </Box>
      ) : (
        <></>
      )}
      {FieldProps.isLoading ? (
        <Box className="option-container">
          <Box className="item">Loading...</Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default AtsAutoComplete;
