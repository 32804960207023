import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text as Typography,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { ChevronRightIcon, ChevronUpIcon } from "@chakra-ui/icons";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import {
  useGetCareersMutation,
  useListCareersSearchMutation,
  useSubmitApplicationMutation,
} from "store/jobs.slice";
import { cleanUpload, setResumeUploaded } from "store/uploads.slice";

import NavBar from "components/app/Navbar";
import Footer from "components/app/Footer";
import AtsDropZone from "components/app/Dropzone";
import AddressComponent from "./components/AddressComponent";

import { BASE_URL } from "constants/values";

import LoadingPage from "components/app/Loading";
import JobRecruiter from "../job-details/job-recruiter";
import SuccessAlert from "./components/SuccessAlert";

const JobApplicationPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const toast = useToast();

  const [reqList] = useListCareersSearchMutation();
  const [reqGetCareers, resGetCareers] = useGetCareersMutation();

  const [reqSubmitApplication, resSubmitApplication] =
    useSubmitApplicationMutation();
  const [isLoading, setIsloading] = useState(false);

  const { jobData, jobCareersPgBtn } = useSelector((state: any) => state.jobs);
  const { resumeAttachments } = useSelector((state: any) => state.uploads);

  const tokenRefreshRate = 60000; // Refresh rate in milliseconds, e.g., 60000 for every minute

  const jobID = params.jobId;

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (Object.keys(jobData).length === 0 && jobID) {
      reqList(jobCareersPgBtn);
      reqGetCareers({ id: Number(jobID) });
    }
  }, [jobCareersPgBtn, jobID]);

  const initialValues = {
    first_name: "",
    last_name: "",
    personal_phone: "",
    email: "",
    zip_code: "",
    city: "",
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      first_name: Yup.string()
        .required("First name is required.")
        .matches(/^[a-z ,.'-]+$/gi, "First name is invalid."),
      last_name: Yup.string()
        .required("Last name is required.")
        .matches(/^[a-z ,.'-]+$/gi, "Last name is invalid."),
      personal_phone: Yup.string()
        .matches(/^[\d ()+-]+$/gi, "Mobile is invalid")
        .required("Mobile is required."),
      email: Yup.string()
        .email("Invalid email.")
        .required("Email is required."),
      zip_code: Yup.string().required("Zip Code is required."),
      city: Yup.string().required("City is required."),
    })
  );

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    try {
      const token = await executeRecaptcha("yourAction");
      setCaptchaToken(token); // Set the captcha token state
      return token;
    } catch (error) {
      console.error("ReCAPTCHA Error:", error);
      return null;
    }
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();

    const intervalId = setInterval(() => {
      handleReCaptchaVerify();
    }, tokenRefreshRate);

    return () => clearInterval(intervalId);
  }, [handleReCaptchaVerify, tokenRefreshRate]);

  const onSubmit = async (data: any) => {
    try {
      // const init_file = await changeUploadName(uploaded, prefix);
      let resume_id;

      if (resumeAttachments.length > 0) {
        const url = BASE_URL + "/careers/resume/upload";
        let formData = new FormData();
        formData.append(
          "file",
          resumeAttachments[0]?.file,
          resumeAttachments[0]?.file?.name
        );
        // readyToUpload && (
        setIsloading(true);
        await axios
          .post(url, formData, { withCredentials: true })
          .then((res) => {
            dispatch(setResumeUploaded(res.data));
            resume_id = res.data.data.id;
            setIsloading(false);
          })
          .catch((err) => {
            console.log("err", err);
            setIsloading(false);
            const title = "Submit Application";
            const description = "Warning unverified captcha";
            toast({
              title: title,
              description: description,
              status: "error",
              isClosable: true,
              duration: 3000,
              position: "top",
            });
          });
      }
      const captchaToken = await executeRecaptcha("yourAction");
      let token = await handleReCaptchaVerify();
      console.log({ captchaToken });
      const newParams = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        mobile_number: data.personal_phone,
        resume_id: resume_id,
        zip_code: data.zip_code,
        city: data.city,
        captcha: captchaToken,
        job_id: jobID,
        utm_id: window.sessionStorage.getItem("utm_id"),
      };
      await reqSubmitApplication(newParams);
    } catch (e) {
      console.log("there was an error");
      const title = "Submit Application";
      const description = "Warning unverified captcha";
      toast({
        title: title,
        description: description,
        status: "error",
        isClosable: true,
        duration: 3000,
        position: "top",
      });
    }
  };

  useEffect(() => {
    if (resSubmitApplication.isSuccess) {
      onOpen();
      dispatch(cleanUpload({ resumeUploaded: [] }));
      dispatch(cleanUpload({ resumeAttachments: [] }));
    }
  }, [resSubmitApplication.isSuccess]);

  useEffect(() => {
    return () => {
      console.log("unmounting...");
      dispatch(cleanUpload({ resumeAttachments: [] }));
      dispatch(cleanUpload({ resumeUploaded: [] }));
    };
  }, []);

  useEffect(() => {
    if (resSubmitApplication.isError) {
      const title = "Submit Application";
      const description = "Warning unverified captcha";
      toast({
        title: title,
        description: description,
        status: "error",
        isClosable: true,
        duration: 3000,
        position: "top",
      });
    }
  }, [resSubmitApplication.isError]);

  return (
    <Box bg="default.white.800" fontFamily="NunitoSans Regular" zIndex={1}>
      <NavBar />

      {resGetCareers.isLoading ? (
        <LoadingPage />
      ) : (
        <Box
          id="top"
          bgImage="linear-gradient(90deg, #6930CA 0%, #7925D6 46.5%, #B100FF 100%)"
          bgSize="cover"
          bgPosition="center"
          height="50vh"
          position="relative"
        >
          <SuccessAlert isOpen={isOpen} onClose={onClose} />

          <Container maxW={["100%", "80%"]}>
            <Box margin="0 auto" pt={["50px", "100px"]}>
              <Breadcrumb
                spacing="8px"
                separator={<ChevronRightIcon color="white" />}
                p={["16px 30px", "16px 0"]}
                fontSize="0.875rem"
              >
                <BreadcrumbItem>
                  <BreadcrumbLink href="/" color="#FFFFFF80">
                    Job Listing
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <BreadcrumbLink
                    href={`/job-details/${jobData?.id || jobID}`}
                    color="#FFFFFF80"
                  >
                    Job Details
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <BreadcrumbLink isCurrentPage color="white">
                    Job Application
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <JobRecruiter isForm />
            </Box>
          </Container>

          <Container maxW={["90%", "80%"]}>
            <Box margin="0 auto" pt={["20px", "20px"]}>
              <Box m="40px auto 24px">
                <Typography
                  fontFamily="NunitoSans Bold"
                  fontSize="24px"
                  fontWeight={700}
                  color="#B100FF"
                >
                  Apply for this job
                </Typography>
                <Typography
                  fontSize="14px"
                  fontWeight={600}
                  color="#2B2D42"
                  pb="24px"
                  mt="16px"
                >
                  Upload your resume/cv in seconds with the autofill option.
                </Typography>
                <Divider borderColor="#CEB8FF" />
              </Box>
              <Box pb="100px" color="#2B2D42">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box>
                        <Box mb="34px">
                          <FormControl>
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              fontWeight={600}
                            >
                              Resume/CV{" "}
                              <Box as="span" color="#6930CA">
                                *
                              </Box>
                            </FormLabel>
                          </FormControl>
                          <AtsDropZone
                            isResume
                            isJobApplication
                            multipleFile={false}
                          />
                        </Box>
                        <Flex
                          direction={{ base: "column", md: "row" }}
                          gap="32px"
                          mb="34px"
                        >
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.first_name && touched.first_name
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              fontWeight={600}
                            >
                              First Name{" "}
                              <Box as="span" color="#6930CA">
                                *
                              </Box>
                            </FormLabel>
                            <Input
                              id="first_name"
                              name="first_name"
                              type="text"
                              placeholder="First Name"
                              variant="outline"
                              value={values.first_name}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.first_name)}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.last_name && touched.last_name
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              fontWeight={600}
                            >
                              Last Name{" "}
                              <Box as="span" color="#2B2D42">
                                *
                              </Box>
                            </FormLabel>
                            <Input
                              id="last_name"
                              name="last_name"
                              type="text"
                              placeholder="Last Name"
                              variant="outline"
                              value={values.last_name}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.last_name)}
                            </FormErrorMessage>
                          </FormControl>
                        </Flex>
                        <Flex
                          direction={{ base: "column", md: "row" }}
                          gap="32px"
                          mb="34px"
                        >
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.personal_phone && touched.personal_phone
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              fontWeight={600}
                            >
                              Mobile{" "}
                              <Box as="span" color="#2B2D42">
                                *
                              </Box>
                            </FormLabel>
                            <Input
                              id="personal_phone"
                              name="personal_phone"
                              type="text"
                              placeholder="Mobile"
                              variant="outline"
                              value={values.personal_phone}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.personal_phone)}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={Boolean(!!errors.email && touched.email)}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              fontWeight={600}
                            >
                              Email{" "}
                              <Box as="span" color="#2B2D42">
                                *
                              </Box>
                            </FormLabel>
                            <Input
                              id="email"
                              name="email"
                              type="email"
                              placeholder="Email"
                              variant="outline"
                              value={values.email}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.email)}
                            </FormErrorMessage>
                          </FormControl>
                        </Flex>

                        <AddressComponent
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          cityField={{
                            name: "city",
                            value: values.city,
                            errors: errors.city,
                            touched: touched.city,
                          }}
                          zipField={{
                            name: "zip_code",
                            value: values.zip_code,
                            errors: errors.zip_code,
                            touched: touched.zip_code,
                          }}
                        />
                      </Box>

                      <Box textAlign={["center", "right"]}>
                        <Button
                          size="lg"
                          variant="solid"
                          background="linear-gradient(90deg,#4900d9 0,#b100ff 100%)"
                          type="submit"
                          isLoading={
                            resSubmitApplication.isLoading || isLoading
                          }
                          disabled={
                            resumeAttachments.length <= 0 ||
                            resSubmitApplication.isLoading ||
                            isLoading
                          }
                          sx={{
                            fontFamily: "NunitoSans Bold",
                            borderRadius: "50px",
                            width: ["100%", "initial"],
                          }}
                        >
                          Submit Application
                        </Button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Container>

          <Footer />
        </Box>
      )}

      <Box>
        <Link
          href="#top"
          sx={{
            background: "rgb(104, 117, 226)",
            display: "block",
            position: "fixed",
            bottom: "80px",
            right: "16px",
            fontSize: "25px",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            textAlign: "center",
            color: "white",
            _hover: {
              color: "white",
            },
          }}
        >
          <ChevronUpIcon />
        </Link>
      </Box>
    </Box>
  );
};

export default JobApplicationPage;
