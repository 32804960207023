export function newFilename(prefix: string, file: string) {
  const explode = file.split('.');
  const extension = explode.splice(-1)[0];
  return prefix + '.' + extension;
}

export const changeName = async (attachments: any, prefix: string) => {
  let newAttachments: any = [];
  await Promise.all(
    attachments.map(async (data: any, key: number) => {
      let newNAme = newFilename(prefix + '-' + (key + 1), data.name);
      let temp = {
        file: {
          path: data.file.path,
          lastModified: data.file.lastModified,
          lastModifiedDate: data.file.lastModifiedDate,
          name: data.file.name,
          size: data.file.size,
          type: data.file.type,
          webkitRelativePath: data.file.webkitRelativePath,
        },
        id: data.id,
        name: data.name,
        progress: data.progress,
        uploading: data.uploading,
      };
      temp.name = newNAme;
      temp.file.name = newNAme;
      newAttachments.push(temp);
    })
  );
  return newAttachments;
};

export const changeUploadName = async (uploaded: any, prefix: string) => {
  let newUpload: any = [];
  await Promise.all(
    uploaded.map(async (item: any, key: number) => {
      newUpload.push({
        file_name: item.file_name,
        file_size: item.file_size,
        file_type: item.file_type,
        location: item.location,
        original_file_name: newFilename(
          prefix + '-' + (key + 1),
          item.original_file_name
        ),
      });
    })
  );

  return newUpload;
};
