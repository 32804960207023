import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api.slice";
import { appError } from "./app.slice";

export const uploadSlice = createSlice({
  name: "uploads",
  initialState: {
    uploaded: [],
    attachments: [],
    resumeUploaded: [],
    resumeAttachments: [],
    resumeParsingQueue: [],
    url: "",
    path: "",
    prefix: "Attachment",
    withPrefix: false,
    uploading: false,
  },
  reducers: {
    uploadList: (state, { payload }) => ({ ...state, ...payload }),
    setAttachment: (state, { payload }) => {
      state.attachments.push(payload);
    },
    removeAttachment: (state, { payload }) => {
      state.attachments.splice(payload, 1);
    },
    removeResumeAttachment: (state, { payload }) => {
      state.resumeAttachments.splice(payload, 1);
    },
    setUploaded: (state, { payload }) => {
      state.uploaded.push(payload.data);
    },
    setResumeUploaded: (state, { payload }) => {
      state.resumeUploaded.push(payload.data);
    },
    setResumeAttachments: (state, { payload }) => {
      state.resumeAttachments.push(payload);
    },
    setProgress: (state, { payload }) => {
      try {
        const index = state.attachments.findIndex(
          (item) => item.id === payload.id
        );
        state.attachments[index] = payload;
      } catch (e) {
        console.log(e);
      }
    },
    setResumeProgress: (state, { payload }) => {
      try {
        const index = state.resumeAttachments.findIndex(
          (item) => item.id === payload.id
        );
        state.resumeAttachments[index] = payload;
      } catch (e) {
        console.log(e);
      }
    },
    setParsingProgress: (state, { payload }) => {
      try {
        const index = state.resumeParsingQueue.findIndex(
          (item) => item.id === payload.id
        );
        state.resumeParsingQueue[index] = payload;
      } catch (e) {
        console.log(e);
      }
    },
    addToParsingQueue: (state, { payload }) => {
      state.resumeParsingQueue.push(payload);
    },
    removeFromParsingQueue: (state, { payload }) => {
      state.resumeParsingQueue.splice(payload, 1);
    },
    cleanUpload: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const {
  uploadList,
  setAttachment,
  removeResumeAttachment,
  removeAttachment,
  setUploaded,
  cleanUpload,
  setResumeAttachments,
  setResumeUploaded,
  setResumeProgress,
  setProgress,
  addToParsingQueue,
  removeFromParsingQueue,
  setParsingProgress,
} = uploadSlice.actions;
export default uploadSlice.reducer;

/* ----------------------------------- API ---------------------------------- */

export const uploadApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    Upload: builder.mutation<{}, FormData>({
      query: (data) => ({
        url: `/upload/file/temp`,
        method: "POST",
        credentials: "include",
        withCredentials: true,
        crossorigin: true,
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUploaded(data));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
    ResumeUpload: builder.mutation<{}, FormData>({
      query: (data) => ({
        url: `/upload/file/temp`,
        method: "POST",
        credentials: "include",
        withCredentials: true,
        crossorigin: true,
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setResumeUploaded(data));
        } catch (error) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const { useUploadMutation, useResumeUploadMutation } = uploadApiSlice;
