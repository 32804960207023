import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useDropzone } from "react-dropzone";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";
import { BASE_URL } from "constants/values";

import {
  Box,
  Flex,
  Icon,
  FormControl,
  Progress,
  List,
  ListItem,
  UnorderedList,
  Image,
} from "@chakra-ui/react";

import {
  setAttachment,
  setResumeUploaded,
  setResumeAttachments,
  removeAttachment,
  useUploadMutation,
  uploadList,
  setProgress,
  setUploaded,
  setResumeProgress,
  useResumeUploadMutation,
  removeResumeAttachment,
} from "store/uploads.slice";

import randomstring from "utils/randomstring";

import { UploadTypes } from "types";
import {
  fileFormat,
  agreementFileFormat,
  fileFormatLabel,
  agreementFileFormatLabel,
} from "constants/files";
import { bytesToSizeStr } from "utils/bytesToSize";
import { changeName, newFilename } from "utils/newFilename";

import PdfIcon from "assets/images/pdf-icon.svg";
import DocIcon from "assets/images/doc-icon.svg";
import DocxIcon from "assets/images/docx-icon.svg";
import FAIcon from "components/lib/FAIcon";

interface AtsDropZoneProps {
  multipleFile: boolean;
  isAgreement?: boolean;
  isResume?: boolean;
  isJobApplication?: boolean;
  // readyToUpload?: boolean;
}

export default function AtsDropZone({
  multipleFile,
  isAgreement,
  isResume,
  isJobApplication,
}: // readyToUpload,
AtsDropZoneProps) {
  const dispatch = useDispatch();

  const {
    attachments,
    prefix,
    withPrefix,
    uploaded,
    resumeUploaded,
    resumeAttachments,
  } = useSelector((state: any) => state.uploads);

  const hasFileFormat =
    isAgreement || isResume ? agreementFileFormat : fileFormat;
  const fileLabel =
    isAgreement || isResume ? agreementFileFormatLabel : fileFormatLabel;

  const [filesStr, setFilesStr] = useState("");
  const [reqUpload] = useUploadMutation();
  const [reqResumeUpload] = useResumeUploadMutation();

  const url = isResume
    ? BASE_URL + "/resume-parser/file"
    : BASE_URL + "/upload/file/temp";

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const uploadPromises = acceptedFiles.map(async (file) => {
        if (!multipleFile) {
          removeAtt(0);
        }
        let id = randomstring();

        let count = isResume
          ? resumeAttachments.length + 1
          : attachments.length + 1;
        let file_name = withPrefix
          ? newFilename(prefix + "-" + count, file.name)
          : file.name;
        dispatch(uploadList({ uploading: true }));
        isResume
          ? dispatch(
              setResumeAttachments({
                id: id,
                name: file_name,
                progress: 0,
                uploading: true,
                file: file,
              })
            )
          : dispatch(
              setAttachment({
                id: id,
                name: file_name,
                progress: 0,
                uploading: true,
                file: file,
              })
            );
        const config = {
          withCredentials: true,
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            isResume
              ? dispatch(
                  setResumeProgress({
                    id: id,
                    name: file_name,
                    progress: percentCompleted,
                    uploading: percentCompleted < 100 ? true : false,
                    file: file,
                  })
                )
              : dispatch(
                  setProgress({
                    id: id,
                    name: file_name,
                    progress: percentCompleted,
                    uploading: percentCompleted < 100 ? true : false,
                    file: file,
                  })
                );
          },
        };
        let formData = new FormData();
        formData.append("file", file, file.name);
        !isJobApplication &&
          (await axios
            .post(url, formData, config)
            .then((res) => {
              isResume
                ? dispatch(setResumeUploaded(res.data))
                : dispatch(setUploaded(res.data));
            })
            .catch((err) => {
              console.log("err", err);
            }));
      });

      await Promise.all(uploadPromises);
    },
    [
      attachments.length,
      resumeAttachments.length,
      dispatch,
      prefix,
      reqUpload,
      reqResumeUpload,
      withPrefix,
    ]
  );

  useEffect(() => {
    dispatch(uploadList({ uploading: false }));
  }, []);

  useEffect(() => {
    // console.log(
    //   'attachments sa ats dropzone ',
    //   isResume ? resumeAttachments : attachments
    // );
  }, [attachments, resumeAttachments]);

  useEffect(() => {
    if (isResume) {
      if (resumeAttachments.length > 0) {
        let stillUploading = false;
        resumeAttachments.map((item: any) => {
          if (item.uploading) {
            stillUploading = true;
          }
        });

        dispatch(uploadList({ uploading: stillUploading }));
      }
    } else {
      if (attachments.length > 0) {
        let stillUploading = false;
        attachments.map((item: any) => {
          if (item.uploading) {
            stillUploading = true;
          }
        });

        dispatch(uploadList({ uploading: stillUploading }));
      }
    }
  }, [uploaded, resumeUploaded]);

  const { getRootProps, getInputProps, fileRejections, open } = useDropzone({
    onDrop,
    multiple: multipleFile,
    accept: hasFileFormat,
    maxSize: 26214400,
  });

  const { ref, ...rootProps } = getRootProps();

  const removeAtt = (key: number) => {
    isResume
      ? dispatch(removeResumeAttachment(key))
      : dispatch(removeAttachment(key));
  };

  const formatString = () => {
    setFilesStr(fileLabel.join(", "));
  };

  const ApplyPrefix = async () => {
    let newAttachments = withPrefix
      ? isResume
        ? await changeName(resumeAttachments, prefix)
        : await changeName(attachments, prefix)
      : isResume
      ? resumeAttachments
      : attachments;
    isResume
      ? await dispatch(uploadList({ resumeAttachments: newAttachments }))
      : await dispatch(uploadList({ attachments: newAttachments }));
  };

  useEffect(() => {
    formatString();
    ApplyPrefix();
  }, [prefix]);
  interface FileWithPath extends File {
    path: string;
  }
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <ListItem>
      {(file as FileWithPath).path} - {bytesToSizeStr(file.size)}
      <UnorderedList>
        {errors.map((e) => (
          <ListItem key={e.code}>{e.message}</ListItem>
        ))}
      </UnorderedList>
    </ListItem>
  ));

  const handleIcon = (fileName: any) => {
    if (fileName.includes("pdf")) {
      return PdfIcon;
    } else if (fileName.includes("docx")) {
      return DocxIcon;
    }
    return DocIcon;
  };

  return (
    <>
      <Box>
        {resumeAttachments.length > 0 ? (
          resumeAttachments.map((item: UploadTypes, key: number) => {
            return (
              <Box
                sx={{
                  borderRadius: "5px",
                  background: "#FAF5FF",
                  p: "12px 16px",
                }}
              >
                <Flex
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <Flex gap="16px" alignItems="center">
                    <Image src={handleIcon(item.name)} height="80px" />
                    <Box
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#1E1E1E",
                      }}
                    >
                      {item.name}
                    </Box>
                  </Flex>
                  <Box mt="6px" onClick={() => removeAtt(key)} cursor="pointer">
                    <FAIcon iconName="close" iconColor="#A6A7B0" />
                  </Box>
                </Flex>
                {item.uploading && (
                  <Progress
                    value={100}
                    w="100%"
                    mt="15px"
                    colorScheme="purple"
                    background="#fff"
                    borderRadius="8px"
                  />
                )}
              </Box>
            );
          })
        ) : (
          <Box
            {...rootProps}
            sx={{
              borderRadius: 5,
              border: "1px solid #6930CA",
              backgroundColor: "#FAF5FF",
              minHeight: "50px",
              boxShadow: "inset 0px 2px 6px 0px #d2d2d291",
              transition: "border-color 300ms cubic-bezier(0, 0, 0.2, 1)",
              mb: "34px",
              fontWeight: 400,
            }}
            onClick={() => {
              open();
            }}
          >
            <input {...getInputProps()} />
            <Flex
              flexDirection={{ base: "column", md: "row" }}
              mb="34px"
              mt="34px"
            >
              <FormControl
                mt={{ base: "34px", md: "0px" }}
                justifyContent="center"
                alignItems="center"
              >
                <Flex gap="10px" flexDir="column" textAlign="center">
                  <Icon
                    as={BsCloudUpload}
                    boxSize={6}
                    style={{ display: "block", margin: "auto" }}
                  />
                  <Box sx={{ fontSize: "14px", color: "#1E1E1E" }}>
                    Drag & Drop or{" "}
                    <Box as="span" color="#6930CA">
                      Choose file
                    </Box>{" "}
                    to upload
                  </Box>
                  <Box sx={{ fontSize: "14px", color: "#A9A9AC" }}>
                    .DOC, .DOCX, .PDF
                  </Box>
                </Flex>
              </FormControl>
            </Flex>
          </Box>
        )}
      </Box>

      <Box color="red.400">
        <List>{fileRejectionItems}</List>
      </Box>
    </>
  );
}
