// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { extendTheme } from '@chakra-ui/react';

import { Button } from './components/button';
import { Link } from './components/link';

export const fonts = {
	fontFamily: 'Proxima Nova Regular',
	fontStyle: 'Semibold',
};

export const colors = {
	primary: {
		100: '#6930CA1A',
		400: '#A83DF9',
		800: '#6930CA',
	},

	secondary: {
		100: '#D9E7F2',
		400: '#479CFA',
		600: '#2164C8',
		800: '#0061AB',
	},

	default: {
		borderlight: '#E8E8E8',
		borderdark: '#A6A7B0',
		secondarytext: '#6B6C7B',
		primarytext: '#2B2D42',
		disabled: '#BABABA',

		dark: '#000000',
		yellow: '#FFC536',
		white: {
			100: '#FFFFFF',
			200: '#EEEEEE',
			400: '#E6E9EE',
			600: '#F0F2F5',
			800: '#F9FAFB',
		},
		gray: {
			100: '#C5C5C5',
			200: '#E6E9EE',
			400: '#DFE0E3B2',
			600: '#9B9B9B',
			800: '#545454',
		},
	},

	success: { 100: '#D9F1E0', 400: '#00A331', 800: '#00AD54' },
	warn: { 100: '#F9ECDA', 400: '#D68309', 800: '#E97825' },
	caution: { 100: '#CB2C2C1A', 400: '#CB2C2C', 800: '#FF3C1F' },
};

export const fontSizes = {
	xs: '10px',
	sm: '12px',
	md: '14px',
	lg: '18px',
	xl: '21px',
	'2xl': '32px',
	'3xl': '40px',
};

export const fontWeights = {
	normal: 400,
	regular: 500,
	bold: 600,
};

export const borderRadius = {
	radii: {
		none: '0',
		sm: '1px',
		base: '2px',
		md: '4px',
		lg: '6px',
		xl: '8px',
	},
};

const theme = extendTheme({
	fonts,
	colors,
	...borderRadius,
	fontSizes,
	fontWeights,
	components: { Button, Link },
});

export default theme;
