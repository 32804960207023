import React from "react";
import { Routes, Route } from "react-router";
import Loader from "../Library/Loader";

import "bootstrap/dist/css/bootstrap.min.css";
import pageRoute from "./pageRoute";

import { PageRouteProps } from "types";

const App = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <Routes>
        {pageRoute.map((page: PageRouteProps) => (
          <Route key={page.name} path={page.path} element={page.component} />
        ))}
      </Routes>
    </React.Suspense>
  );
};

export default App;
