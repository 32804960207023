import { CloseIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import AtsSelect from "components/app/AtsSelect";
import FAIcon from "components/lib/FAIcon";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { useListCareersSearchMutation } from "store/jobs.slice";

import * as Yup from "yup";
import AddressComponent from "./AddressComponent";

const SearchComponent = () => {
  const [reqList] = useListCareersSearchMutation();

  const { jobCareersPgBtn } = useSelector((state: any) => state.jobs);

  const jobs_work_type = [
    { id: null, type: "All" },
    { id: 1, type: "Remote" },
    { id: 2, type: "On Site" },
    { id: 3, type: "Hybrid" },
  ];

  const workStatusOptions = jobs_work_type.map((item: any) => ({
    value: item.id,
    label: item.type,
  }));

  const initialValues = {
    title: "",
    city: "",
    location: [] as any,
    work_type_id: null as any,
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      title: Yup.string().notRequired(),
      location: Yup.array().nullable().notRequired(),
      city: Yup.string().notRequired(),
      work_type_id: Yup.number().nullable(),
    })
  );

  const onSubmit = (data: any) => {
    data.location = data.city !== "" ? data.location : [];

    const payload = {
      ...jobCareersPgBtn,
      location: data.location,
      title: data.title,
      work_type_id: data.work_type_id,
    };
    reqList(payload);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Flex
            sx={{
              flexDir: ["column", "row"],
              gap: ["10px", "initial"],
              alignItems: ["stretch", "center"],
            }}
          >
            <Box>
              <InputGroup>
                <Input
                  id="title"
                  name="title"
                  type="text"
                  placeholder="Search Job Title"
                  variant="outline"
                  value={values.title}
                  onChange={handleChange}
                  width={["100%", "300px"]}
                  height={["40px", "60px"]}
                  borderRadius={["6px", "6px 0 0 6px"]}
                  background="white"
                />
                {values.title && (
                  <InputRightElement height={["40px", "60px"]}>
                    <Box
                      onClick={() => {
                        setFieldValue("title", "");
                        onSubmit({ ...values, title: "" });
                      }}
                      cursor="pointer"
                      color="primary.800"
                    >
                      <SmallCloseIcon />
                    </Box>
                  </InputRightElement>
                )}
              </InputGroup>
            </Box>

            <AddressComponent
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              onSubmit={onSubmit}
              values={values}
              cityField={{
                name: "city",
                value: values.city,
                errors: errors.city,
                touched: touched.city,
              }}
              locationField={{
                name: "location",
                value: values.location,
                errors: errors.location,
                touched: touched.location,
              }}
            />

            <AtsSelect
              name="work_type_id"
              options={workStatusOptions}
              placeholder="Work Type"
              value={
                jobs_work_type
                  .filter((item: any) => values.work_type_id === item.id)
                  .map((item: any) => ({
                    value: item.id,
                    label: item.type,
                  })) || []
              }
              isClearable
              variant="outline"
              onChange={(e: any) => {
                setFieldValue("work_type_id", e.value);
              }}
              styles={{
                placeholder: (styles: any) => ({
                  ...styles,
                  fontSize: "14px",
                  color: "#A0AEC0",
                }),
                control: (styles: any) => ({
                  ...styles,
                  height: "40px",
                  width: "100%",
                  border: "1px solid #E2E8F0",
                  borderRadius: "6px",
                  cursor: "pointer",
                  boxShadow: "none",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#1a202c",
                  "&:hover": { border: "1px solid #E2E8F0" },
                  "@media (min-width: 768px)": {
                    borderRadius: "0 6px 6px 0",
                    width: "350px",
                    height: "60px",
                  },
                }),
                dropdownIndicator: (styles: any) => ({
                  ...styles,
                  color: "#6930CA",
                }),
                multiValue: (styles: any) => ({
                  ...styles,
                  background: "#F7FAFC",
                  border: "1px solid #E2E8F0",
                  borderRadius: "6px",
                  "&& div:first-of-type": {
                    color: "#6930CA",
                    fontWeight: 600,
                  },
                  "@media (min-width: 768px)": {
                    borderRadius: "0 6px 6px 0",
                  },
                }),
              }}
              components={{
                Option: ({ label, innerProps }: any) => (
                  <Box onClick={(e) => innerProps.onClick(e)}>
                    <Flex
                      sx={{
                        fontFamily: "NunitoSans Regular",
                        alignItems: "center",
                        justifyContent: "space-between",
                        p: "12px 24px",
                        cursor: "pointer",
                      }}
                      _hover={{
                        backgroundColor: "rgba(239, 229, 255, 0.4)",
                        color: "primary.800",
                      }}
                    >
                      <Box
                        sx={{
                          fontWeight: 600,
                          fontSize: "12px",
                        }}
                      >
                        {label}
                      </Box>
                    </Flex>
                  </Box>
                ),
              }}
            />
            <Box
              sx={{
                button: {
                  fontWeight: 800,
                },
              }}
            >
              <Button
                variant="unstyled"
                mt={["15px", "initial"]}
                ml={["initial", "15px"]}
                p={["initial", "30px 20px"]}
                color="white"
                size="lg"
                display="flex"
                float={["right", "initial"]}
                width={["100%", "auto"]}
                type="submit"
                fontFamily="NunitoSans Bold"
              >
                <Box mr="5px">
                  <FAIcon iconName="search" />
                </Box>
                Search Jobs
              </Button>
            </Box>
          </Flex>
        </form>
      )}
    </Formik>
  );
};

export default SearchComponent;
