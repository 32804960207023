import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';

export const constantsSlice = createSlice({
  name: 'constants',
  initialState: {
    work_types: [],
    educations: [],
    compensations: [],
    employment_types: [],
    candidate_work_status: [],
    job_specs: [],
  },
  reducers: {
    loadConstant: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { loadConstant } = constantsSlice.actions;
export default constantsSlice.reducer;

const constantsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    GetWorkTypes: builder.mutation({
      query: () => ({
        url: '/constants/worktypes',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ work_types: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetEducations: builder.mutation({
      query: () => ({
        url: '/constants/educations',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ educations: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetCompensations: builder.mutation({
      query: () => ({
        url: '/constants/compensations',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ compensations: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetEmpType: builder.mutation({
      query: () => ({
        url: '/constants/employment-types',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ employment_types: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetCandidateWorkStatus: builder.mutation({
      query: () => ({
        url: '/constants/candidate-work-status',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ candidate_work_status: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetJobSpecs: builder.mutation({
      query: () => ({
        url: '/constants/job-specifications',
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(loadConstant({ job_specs: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useGetWorkTypesMutation,
  useGetEducationsMutation,
  useGetCompensationsMutation,
  useGetEmpTypeMutation,
  useGetCandidateWorkStatusMutation,
  useGetJobSpecsMutation,
} = constantsApiSlice;
