import { Flex } from "@chakra-ui/react";
import * as React from "react";
import Button from "./Button";

interface Props {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<Props> = ({
  totalPages,
  currentPage,
  onPageChange,
}) => {
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const pageClick = (page: number) => {
    currentPage = page;
    onPageChange(page);
  };

  const handlePageButton = () => {
    if (totalPages > 10) {
      if (currentPage < 5) {
        // Display pages 1 to 5 and the last page
        return [
          ...Array.from(Array(5), (page, key) => (
            <Button
              key={key}
              variant={key === currentPage - 1 ? "active" : "ghost"}
              onClick={() => pageClick(key + 1)}
            >
              {key + 1}
            </Button>
          )),
          <span key="ellipsis1">...</span>,
          <Button
            key={totalPages}
            variant={currentPage === totalPages ? "active" : "ghost"}
            onClick={() => pageClick(totalPages)}
          >
            {totalPages}
          </Button>,
        ];
      } else if (currentPage > totalPages - 4) {
        // Display the first page and pages (totalPages - 4) to totalPages
        return [
          <Button
            key={1}
            variant={currentPage === 1 ? "active" : "ghost"}
            onClick={() => pageClick(1)}
          >
            1
          </Button>,
          <span key="ellipsis1">...</span>,
          ...Array.from(Array(5), (page, key) => (
            <Button
              key={key + (totalPages - 4)}
              variant={
                key + (totalPages - 4) === currentPage ? "active" : "ghost"
              }
              onClick={() => pageClick(key + (totalPages - 4))}
            >
              {key + (totalPages - 4)}
            </Button>
          )),
        ];
      } else {
        // Display the first page, pages around the current page, and the last page
        return [
          <Button
            key={1}
            variant={currentPage === 1 ? "active" : "ghost"}
            onClick={() => pageClick(1)}
          >
            1
          </Button>,
          <span key="ellipsis1">...</span>,
          ...Array.from(Array(5), (page, key) => (
            <Button
              key={key + (currentPage - 2)}
              variant={
                key + (currentPage - 2) === currentPage ? "active" : "ghost"
              }
              onClick={() => pageClick(key + (currentPage - 2))}
            >
              {key + (currentPage - 2)}
            </Button>
          )),
          <span key="ellipsis2">...</span>,
          <Button
            key={totalPages}
            variant={currentPage === totalPages ? "active" : "ghost"}
            onClick={() => pageClick(totalPages)}
          >
            {totalPages}
          </Button>,
        ];
      }
    } else {
      // Display all pages if totalPages is less than or equal to 10
      return Array.from(Array(totalPages), (page, key) => (
        <Button
          key={key}
          variant={key === currentPage - 1 ? "active" : "ghost"}
          onClick={() => pageClick(key + 1)}
        >
          {key + 1}
        </Button>
      ));
    }
  };

  return (
    <Flex
      justifyContent="flex-end"
      alignItems="center"
      color="#C5C5C5"
      paddingRight="20px"
    >
      <Button
        variant="ghost"
        iconColor="#C5C5C5"
        iconName="chevron-left"
        onClick={handlePreviousClick}
        disabled={currentPage === 1}
      />
      <Flex color="#6B6C7B" fontSize="14px" alignItems="baseline">
        {handlePageButton()}
      </Flex>
      <Button
        variant="ghost"
        iconColor="#C5C5C5"
        iconName="chevron-right"
        onClick={handleNextClick}
        disabled={currentPage === totalPages}
      />
    </Flex>
  );
};

export default Pagination;
