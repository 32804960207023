import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Link,
  Stack,
  Text as Typography,
} from "@chakra-ui/react";
import { ChevronUpIcon } from "@chakra-ui/icons";

import LocationPin from "assets/images/location-pin.svg";
import LocationPinW from "assets/images/location-pin-w.svg";

import {
  getJob,
  listJobs,
  useListCareersSearchMutation,
} from "store/jobs.slice";

import Navbar from "components/app/Navbar";
import Footer from "components/app/Footer";
import BoxLoading from "components/app/Skeleton";
import SearchComponent from "./components/SearchComponent";

import Pagination from "Library/Pagination";

const JobListingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [reqListSearch, resList] = useListCareersSearchMutation();

  const { jobs, jobPage, jobCareersPgBtn } = useSelector(
    (state: any) => state.jobs
  );

  const [isHovered, setIsHovered] = useState({ isHover: false, id: null });

  useEffect(() => {
    reqListSearch(jobCareersPgBtn);
    dispatch(getJob({ jobData: {} }));
  }, [jobCareersPgBtn]);

  const pageChange = async (page: number) => {
    let newParam = {
      ...jobCareersPgBtn,
      page: page,
    };
    console.log("trigger");
    dispatch(
      listJobs({
        jobCareersPgBtn: newParam,
      })
    );
  };

  const handleClick = () => {
    const element = document.querySelector("#job-openings");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const locationParse = (location: any) => {
    try {
      const loc = [location.city, location.state].join(", ");
      if (loc !== ", ") {
        return [location.city, location.state].join(", ");
      }
      return "-";
    } catch (e) {
      console.log(e);
      return "";
    }
  };

  const EmploymentType = (type: number) => {
    if (type === 1) return "Full-Time";
    if (type === 2) return "Part-Time";
    if (type === 3) return "Temporary";
    if (type === 4) return "Contract";
    if (type === 5) return "Internship";
    return <Box>{type || "-"}</Box>;
  };

  return (
    <Box bg="default.white.800" fontFamily="NunitoSans Regular">
      <Navbar />

      <Box
        id="top"
        bgImage="linear-gradient(90deg, #6930CA 0%, #7925D6 46.5%, #B100FF 100%)"
        bgSize="cover"
        bgPosition="center"
        height={["100%", "100%"]}
        position="relative"
      >
        <Container maxW={["90%", "80%"]}>
          <Box py={["80px", "80px"]} pb={["100px", "80px"]}>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
                md: "repeat(1, 1fr)",
              }}
              gap={6}
              marginTop={6}
            >
              <GridItem colSpan={1}>
                <Typography
                  fontSize={["70px", "100px"]}
                  fontWeight={900}
                  letterSpacing=".01em"
                  lineHeight="1.1"
                  color="white"
                  mb={["10px", "16px"]}
                  fontFamily="NunitoSans ExtraBold"
                >
                  Job
                </Typography>
                <Typography
                  fontSize={["70px", "100px"]}
                  fontWeight={900}
                  letterSpacing=".01em"
                  lineHeight="1.1"
                  color="white"
                  mb="16px"
                  ml={["40px", "60px"]}
                  fontFamily="NunitoSans ExtraBold"
                >
                  Search
                </Typography>
                <Typography
                  fontSize={["16px", "26px"]}
                  color="white"
                  mb={["40px", "77px"]}
                >
                  Start your journey with us by browsing available jobs.
                </Typography>

                <SearchComponent />
              </GridItem>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Container maxW={["90%", "80%"]}>
        <Box id="job-openings" py={["20px", "64px"]}>
          {resList.isLoading ? (
            <BoxLoading />
          ) : (
            <Box>
              <Stack mb="20px">
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(2, 1fr)",
                  }}
                  gap={6}
                  marginTop={6}
                >
                  {jobs?.map((val: any) => {
                    return (
                      <GridItem
                        key={val.id}
                        colSpan={1}
                        border="1px solid #e5e9f3"
                        borderRadius={["15px", "25px"]}
                        p="24px 32px"
                        _hover={{
                          background: [
                            "transparent",
                            "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #6930CA 0%, #B100FF 100%)",
                          ],
                        }}
                        onMouseEnter={() =>
                          setIsHovered({ isHover: true, id: val.id })
                        }
                        onMouseLeave={() =>
                          setIsHovered({ isHover: false, id: val.id })
                        }
                        cursor="pointer"
                        onClick={() => {
                          navigate(`/job-details/${val.id}`);
                        }}
                      >
                        <Typography
                          fontSize="12px"
                          fontWeight={400}
                          color={
                            isHovered.id === val.id && isHovered.isHover
                              ? "white"
                              : "#2B2D42"
                          }
                          textTransform="uppercase"
                        >
                          {val?.specification || "N/A"}
                        </Typography>
                        <Box
                          mt="8px"
                          mb="12px"
                          minH="12px"
                          fontFamily="NunitoSans Bold"
                          fontSize={["24px", "28px"]}
                          textTransform="capitalize"
                          fontWeight={700}
                          color={
                            isHovered.id === val.id && isHovered.isHover
                              ? "white"
                              : "primary.800"
                          }
                        >
                          {val?.job_ad_title || "-"}
                        </Box>
                        <Flex align="center" gap="16px">
                          {val?.workplace_type && (
                            <Box
                              p="8px 16px"
                              borderRadius="20px"
                              background={
                                isHovered.id === val.id && isHovered.isHover
                                  ? "rgba(250, 245, 255, 0.20)"
                                  : "#FAF5FF"
                              }
                              color={
                                isHovered.id === val.id && isHovered.isHover
                                  ? "white"
                                  : "primary.800"
                              }
                              fontFamily="NunitoSans Bold"
                              fontWeight={800}
                              fontSize={["14px", "16px"]}
                              textTransform="capitalize"
                              minW="fit-content"
                              textAlign="center"
                            >
                              {val?.workplace_type}
                            </Box>
                          )}
                          {
                            val?.workplace_type != "Remote" && (
                              <Flex sx={{ flexDir: "row", gap: "4px" }}>
                                <Image
                                  src={
                                    isHovered.id === val.id && isHovered.isHover
                                      ? LocationPinW
                                      : LocationPin
                                  }
                                />
                                <Typography
                                  fontSize={["14px", "16px"]}
                                  fontWeight={400}
                                  color={
                                    isHovered.id === val.id && isHovered.isHover
                                      ? "white"
                                      : "#2B2D42"
                                  }
                                >
                                  {val?.office ? locationParse(val?.office) : "-"}
                                </Typography>
                              </Flex>
                            )
                          }

                        </Flex>
                        <Flex gap="8px" flexDir="column" mt="12px">
                          <Typography
                            fontSize={["14px", "16px"]}
                            color={
                              isHovered.id === val.id && isHovered.isHover
                                ? "white"
                                : "#2B2D42"
                            }
                          >
                            {val.experience_level && (
                              <>
                                Candidates with{" "}
                                <span>
                                  <b>
                                    {val?.experience_level ===
                                      "Not Applicable" ? (
                                      <>any experience</>
                                    ) : (
                                      <>{val?.experience_level} of experience</>
                                    )}
                                  </b>
                                </span>
                              </>
                            )}
                          </Typography>
                          <Typography
                            fontSize={["14px", "16px"]}
                            color={
                              isHovered.id === val.id && isHovered.isHover
                                ? "white"
                                : "#2B2D42"
                            }
                          >
                            {val?.employment_type
                              ? EmploymentType(val?.employment_type)
                              : " "}
                          </Typography>
                          <Typography
                            fontSize={["14px", "16px"]}
                            fontWeight={700}
                            color={
                              isHovered.id === val.id && isHovered.isHover
                                ? "white"
                                : "#2B2D42"
                            }
                          >
                            {val?.salary_range_start > 0 &&
                              `$${val?.salary_range_start?.toLocaleString()}`}
                            {val?.salary_range_start > 0 &&
                              ` - $${val?.salary_range_end?.toLocaleString()}`}
                          </Typography>
                        </Flex>
                      </GridItem>
                    );
                  })}
                </Grid>
              </Stack>
              <Pagination
                totalPages={jobPage.lastPage}
                currentPage={jobPage.currentPage}
                onPageChange={(page) => pageChange(page)}
              />
            </Box>
          )}
        </Box>
      </Container>

      <Footer />

      <Box onClick={handleClick}>
        <Link
          href="#top"
          sx={{
            background: "linear-gradient(90deg,#4900d9 0,#b100ff 100%)",
            display: "block",
            position: "fixed",
            bottom: "80px",
            right: "16px",
            fontSize: "25px",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            textAlign: "center",
            color: "white",
            _hover: {
              color: "white",
            },
          }}
        >
          <ChevronUpIcon />
        </Link>
      </Box>
    </Box>
  );
};

export default JobListingPage;
