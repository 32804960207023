import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Text as Typography,
} from "@chakra-ui/react";

import User from "assets/images/user.png";
import LocationPin from "assets/images/location-pin.svg";
import LinkedIn from "assets/images/linkedin.svg";
import Mail from "assets/images/mail.svg";
import MinusCircle from "assets/images/minus-circle.svg";

import toUrl from "utils/toUrl";

interface JobRecruiterProps {
  isForm?: boolean;
}

const JobRecruiter = ({ isForm }: JobRecruiterProps) => {
  const navigate = useNavigate();

  const { jobData } = useSelector((state: any) => state.jobs);

  const hasRecruiter = jobData?.recruiter;

  const EmploymentType = (type: number) => {
    if (type === 1) return "Full-Time";
    if (type === 2) return "Part-Time";
    if (type === 3) return "Temporary";
    if (type === 4) return "Contract";
    if (type === 5) return "Internship";
    return <Box>{type || "-"}</Box>;
  };

  const locationParse = (location: any) => {
    try {
      const loc = [location.city, location.state].join(", ");
      if (loc !== ", ") {
        return [location.city, location.state].join(", ");
      }
      return "";
    } catch (e) {
      console.log(e);
      return "";
    }
  };

  const isOpenJobAd = jobData?.job_status === "Open";

  return (
    <Flex
      direction={{ base: "column-reverse", md: "row" }}
      justify="space-between"
      gap={["20px", "42px"]}
    >
      <Box
        width={{ base: "95%", md: hasRecruiter ? "70%" : "100%" }}
        margin={["auto", "initial"]}
        p="24px 32px"
        position="relative"
        background="white"
        borderRadius="25px"
        minH={"375px"}
        color="#2B2D42"
        fontSize={["14px", "16px"]}
        boxShadow={[
          "1px 2px 9px 0px rgba(0, 0, 0, 0.25)",
          "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        ]}
      >
        <Flex justifyContent="space-between" flexDir={["column", "row"]}>
          <Typography color="#2B2D42">
            {jobData?.specification || "N/A"}
          </Typography>
          <Typography color="#2B2D42">
            Job Posted on {moment(jobData?.created_on).format("MMM. DD, YYYY")}
          </Typography>
        </Flex>
        <Box mt="8px" mb="12px" minH="12px">
          <Typography color="#6875E2" fontWeight={700}>
            <Box
              sx={{
                fontSize: ["30px", "40px"],
                fontFamily: "NunitoSans Bold",
                textTransform: "capitalize",
                backgroundClip: "text",
                background:
                  "linear-gradient(90deg, #6930CA 0%, #7925D6 46.5%, #B100FF 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {jobData?.title || "-"}
            </Box>
          </Typography>
        </Box>
        <Flex align="center" gap="16px">
          {jobData?.workplace_type && (
            <Box
              fontFamily="NunitoSans Bold"
              p="8px 16px"
              borderRadius="20px"
              background="#FAF5FF"
              color="primary.800"
              fontWeight={700}
              fontSize={["14px", "16px"]}
              textTransform="capitalize"
              minW="fit-content"
            >
              {jobData?.workplace_type}
            </Box>
          )}
          {jobData?.workplace_type != "Remote" && (
            <Flex sx={{ flexDir: "row", gap: "4px" }}>
              <Image src={LocationPin} />
              <Typography fontWeight={400} color="#2B2D42">
                {jobData?.office ? locationParse(jobData?.office) : "-"}
              </Typography>
            </Flex>
          )}
        </Flex>
        <Flex gap="8px" flexDir="column" mt="32px">
          <Typography fontSize={["14px", "16px"]} color="#2B2D42">
            {jobData.experience_level && (
              <>
                Candidates with{" "}
                <span>
                  <b>
                    {jobData?.experience_level === "Not Applicable" ? (
                      <>any experience</>
                    ) : (
                      <>{jobData?.experience_level} of experience</>
                    )}
                  </b>
                </span>
              </>
            )}
          </Typography>
          <Typography color="#2B2D42">
            {jobData?.employment_type
              ? EmploymentType(jobData?.employment_type)
              : " "}
          </Typography>
          <Typography fontWeight={700} color="#2B2D42">
            {jobData?.salary_start > 0 &&
              `$${jobData?.salary_start?.toLocaleString()}`}
            {jobData?.salary_start > 0 &&
              ` - $${jobData?.salart_end?.toLocaleString()}`}
          </Typography>
        </Flex>

        {!isForm && isOpenJobAd && (
          <Button
            variant="solid"
            background="linear-gradient(90deg,#4900d9 0,#b100ff 100%)"
            border="none"
            fontSize="16px"
            onClick={() => {
              navigate(`/job-application/${jobData?.id}`);
            }}
            sx={{
              fontFamily: "NunitoSans Bold",
              mt: ["30px", !hasRecruiter && "30px"],
              position: "initial",
              bottom: "20px",
              width: ["100%", "initial"],
              borderRadius: "50px",
              padding: ["28px 16px", "11px 24px"],
            }}
          >
            I'm interested
          </Button>
        )}

        {!isOpenJobAd && (
          <Flex
            gap="10px"
            sx={{
              mt: ["30px", !hasRecruiter && "30px"],
              position: ["initial", hasRecruiter && "absolute"],
              bottom: "40px",
            }}
          >
            <Image src={MinusCircle} />
            <Box
              sx={{
                fontSize: "16px",
                fontWeight: 700,
                color: "#CB2C2C",
              }}
            >
              No longer accepting applications
            </Box>
          </Flex>
        )}
      </Box>
      {hasRecruiter && (
        <Box
          width={{ base: "95%", md: "30%" }}
          margin={["auto", "initial"]}
          p="16px"
          pb="35px"
          background="#6930CA"
          borderRadius="25px"
          color="white"
          fontSize="14px"
          boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
        >
          <Image
            src={hasRecruiter?.profile_picture || User}
            sx={{
              borderRadius: "8px",
              width: "100%",
              height: "250px",
              objectFit: "contain",
            }}
          />
          <Typography mt="16px" fontSize="12px" textTransform="uppercase">
            Recruiter
          </Typography>
          <Flex gap="16px" justifyContent="space-between" alignItems="flex-end">
            <Typography mt="10px" fontSize="20px" fontWeight={700}>
              {[hasRecruiter?.first_name, hasRecruiter?.last_name].join(" ")}
            </Typography>
            <Flex gap="10px">
              {hasRecruiter?.linkedin_url && (
                <Link
                  href={toUrl(hasRecruiter?.linkedin_url)}
                  target="_blank"
                  rel="noreferrer"
                  cursor="pointer"
                >
                  <Image src={LinkedIn} />
                </Link>
              )}

              {hasRecruiter?.email && (
                <Link
                  href={`mailto:${hasRecruiter?.email}`}
                  target="_blank"
                  rel="noreferrer"
                  cursor="pointer"
                >
                  <Image src={Mail} />
                </Link>
              )}
            </Flex>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default JobRecruiter;
