import { Box, Stack, Grid, Skeleton, GridItem } from "@chakra-ui/react";

export default function BoxLoading() {
  const box = 2;
  const stack = 3;
  return (
    <Stack>
      {Array.from(Array(stack), (a, b) => {
        return (
          <Box mb={6} key={`box-${b}`}>
            <Skeleton height="30px" mb={3} />
            <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={6} mb={6}>
              {Array.from(Array(box), (e, i) => {
                return (
                  <GridItem key={`box-${b}-${i}`}>
                    <Skeleton height="120px" />
                  </GridItem>
                );
              })}
            </Grid>
          </Box>
        );
      })}
    </Stack>
  );
}
