import { defineStyleConfig } from '@chakra-ui/styled-system';

export const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: 'bold',
    borderRadius: 'md',
    border: '1px solid',
    ':focus:not(:focus-visible)': {
      shadow: 'none',
    },
    _disabled: { pointerEvents: 'not-allowed' },
  },

  sizes: {
    sm: {
      fontSize: 'xs',
      px: 2,
      py: 1,
    },
    md: {
      fontSize: 'sm',
      px: 4,
      py: 2,
    },
    lg: {
      fontSize: 'md',
      px: 6,
      py: 4,
    },
  },

  variants: {
    solid: {
      backgroundColor: 'primary.800',
      borderColor: 'primary.800',
      color: 'default.white.100',
      _hover: {
        backgroundColor: 'primary.800',
      },
      _active: {
        backgroundColor: 'primary.800',
      },
    },
    outline: {
      backgroundColor: 'white',
      borderColor: 'primary.800',
      color: 'primary.800',
      _hover: {
        backgroundColor: 'primary.100',
      },
      _active: {
        backgroundColor: 'primary.100',
      },
    },
    active: {
      border: 'none',
      backgroundColor: 'primary.100',
      borderColor: 'primary.800',
      color: 'primary.800',
    },
    basic: {
      borderColor: 'default.borderlight',
      backgroundColor: 'default.white.100',
      color: 'default.primarytext',
      _hover: {
        backgroundColor: 'default.white.100',
      },
      _active: {
        backgroundColor: 'default.white.100',
      },
    },

    ghost: {
      border: 'none',
      color: 'default.gray.800',
    },
    skeleton: {
      borderColor: 'default.borderlight',
      backgroundColor: 'transparent',
      color: 'default.primarytext',
      _hover: {
        backgroundColor: 'transparent',
      },
      _active: {
        backgroundColor: 'transparent',
      },
    },
  },

  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
});
