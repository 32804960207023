export const fileFormat: string[] = [
  'application/doc',
  'application/docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/csv',
  'application/xls',
  'application/vnd.ms-excel',
  'application/xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf',
  'application/txt',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/zip',
  'audio/mp3',
  'audio/*',
  'video/mp4',
  'video/mov',
  'video/mpeg',
  '.csv',
];

export const fileFormatLabel: string[] = [
  '.doc',
  'docx',
  '.csv',
  '.xls',
  '.xlsx',
  '.pdf',
  '.txt',
  '.jpg',
  '.jpeg',
  '.png',
  '.zip',
  '.mp3',
  '.mp4',
  '.mov',
  '.mpeg',
];

export const agreementFileFormat: string[] = [
  'application/doc',
  'application/docx',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
];

export const agreementFileFormatLabel: string[] = ['.doc', '.docx', '.pdf'];

export const maxUpload: number = 25; //MB
