import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api.slice";
import appReducer from "./app.slice";
import userReducer from "./user.slice";
import jobsReducer from "./jobs.slice";
import uploadsReducer from "./uploads.slice";
import clientReducer from "./client.slice";
import constantsReducer from "./constant.slice";
import geoLocationReducer from './geo.slice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    jobs: jobsReducer,
    uploads: uploadsReducer,
    clients: clientReducer,
    constants: constantsReducer,
    geolocation: geoLocationReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   //* Ignore these action types
      //   ignoredActions: ['app/openModal', 'app/openDrawer'],
      //   //* Ignore these field paths in all actions
      //   // ? ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
      //   //* Ignore these paths in the state
      //   ignoredPaths: ['app.modal', 'app.drawer'],
      // },
      serializableCheck: false, //file upload issue need to set to false to ignore serialization
    }).concat(apiSlice.middleware),

  devTools: true,
});
