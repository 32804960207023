import { useRef } from "react";
import { useNavigate } from "react-router";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Image,
} from "@chakra-ui/react";

import Envelope from "assets/images/envelope-icon.svg";

const SuccessAlert = ({ isOpen, onClose }: any) => {
  const navigate = useNavigate();
  const cancelRef = useRef();

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      onCloseComplete={() => navigate(`/`)}
    >
      <AlertDialogOverlay />

      <AlertDialogContent
        background="#FAF5FF"
        borderRadius="5px"
        boxShadow="0px 3px 6px 0px rgba(0, 0, 0, 0.25);"
        p="33px 16px"
        fontFamily="NunitoSans Regular"
        width={["90%", "initial"]}
      >
        <AlertDialogCloseButton onClick={() => navigate(`/`)} />
        <AlertDialogBody>
          <Box textAlign="center" color="#2B2D42">
            <Image src={Envelope} margin="auto" />
            <Box
              fontSize="22px"
              fontWeight={700}
              mt="16px"
              mb="12px"
              fontFamily="NunitoSans Bold"
            >
              Thanks for applying!
            </Box>
            <Box fontSize="16px">
              You have successfully applied to the job. We will keep you posted.
            </Box>
          </Box>
        </AlertDialogBody>
        <AlertDialogFooter p={0}>
          <Button
            variant="solid"
            background="linear-gradient(90deg,#4900d9 0,#b100ff 100%)"
            border="none"
            fontFamily="NunitoSans Bold"
            fontSize="16px"
            color="#fff"
            sx={{
              height: "33px",
              width: "100%",
              borderRadius: "50px",
              mt: "24px",
              p: "25px 0",
              fontWeight: 700,
            }}
            onClick={() => navigate(`/`)}
          >
            Continue
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default SuccessAlert;
