import { Container, Spinner } from '@chakra-ui/react';

const Loader = () => {
	return (
		<Container>
			<Spinner
				thickness="4px"
				speed="0.55s"
				emptyColor="default.gray.200"
				color="primary.800"
				size="xl"
			/>
		</Container>
	);
};

export default Loader;
