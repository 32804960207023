import { Flex, FormControl, FormErrorMessage, Text } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../../../../assets/css/autocomplete.css";
import { useSearchGeoTypesMutation } from "store/geo.slice";
import AtsAutoComplete from "./Autocomplete";

interface fieldInterface {
  name: string;
  value: any;
  errors?: any;
  touched?: any;
}

interface addCompInt {
  index?: number;
  handleChange: any;
  setFieldValue: any;
  cityField?: fieldInterface;
  locationField?: fieldInterface;
  onSubmit: any;
  values: any;
}

const AddressComponent = ({
  index = 0,
  setFieldValue,
  cityField,
  locationField,
  onSubmit,
  values,
}: addCompInt) => {
  const { geo_location } = useSelector((state: any) => state.geolocation);

  const [itemLocation, setItemLocation] = useState([]);
  const [reqGeo, resGeo] = useSearchGeoTypesMutation();
  const [currentName, setName] = useState("");

  useEffect(() => {
    setGeoItem();
  }, [geo_location, currentName]);

  const setGeoItem = () => {
    if (geo_location.length > 0) {
      let optios: any = [];
      geo_location.map((item: any, key: number) => {
        optios.push({
          label: [item?.city, item?.state, item?.country].join(", "),
          value: item.postal_code,
          city: item.city,
          country: item.country,
          country_code: item.country_code,
          state: item.state,
        });
      });
      setItemLocation(optios);
    } else {
      setItemLocation([]);
    }
  };

  const geoLocationChange = debounce((e: any, column: string) => {
    const search = e.target.value;
    if (search.length > 3) {
      const data = {
        search,
        column,
      };
      reqGeo(data);
    }
  }, 500);

  const handleOnSelect = (item: any, input: string) => {
    setFieldValue(cityField.name, item.city);
    setFieldValue(locationField.name, [item]);
  };

  return (
    <div className="row" key={`jobAdd-${index}`}>
      <FormControl isInvalid={Boolean(cityField.errors && cityField.touched)}>
        <AtsAutoComplete
          name={cityField.name}
          placeholder="Location"
          type="text"
          variant="outline"
          value={cityField.value}
          onChange={(e: any) => {
            setFieldValue(cityField.name, e.target.value);
          }}
          onFocus={() => setName("city")}
          options={itemLocation}
          onKeyUp={(e: any) => geoLocationChange(e, "city")}
          isLoading={resGeo.isLoading}
          onSelect={(item: any) => {
            setFieldValue(cityField.name, item.value);
            handleOnSelect(item, "city");
          }}
          setFieldValue={setFieldValue}
          onSubmit={onSubmit}
          values={values}
        />

        <FormErrorMessage>{String(cityField.errors)}</FormErrorMessage>
      </FormControl>
    </div>
  );
};

export default AddressComponent;
