import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Popover,
  useColorModeValue,
  useDisclosure,
  Image,
  useBreakpointValue,
  Link,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

import Logo from "assets/images/logo.webp";

export default function NavBar() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box fontFamily="Alata">
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        p={["10px", "25px 15px"]}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
        sx={{
          flexDirection: useBreakpointValue({
            base: "row-reverse",
            md: "initial",
          }),
        }}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          justify={{ base: "flex-end", md: "initial" }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            color="#b100ff"
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex
          flex={{ base: 1 }}
          justify={{ base: "flex-start", md: "start" }}
          ml={["initial", "54px"]}
        >
          <Link href="https://talently.com/">
            <Image src={Logo} width={["70%", "18%"]} />
          </Link>
        </Flex>

        <Box sx={{ display: { base: "none", md: "initial" }, pr: "30px" }}>
          <DesktopNav />
        </Box>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("black", "black");
  const linkHoverColor = useColorModeValue("gray.800", "white");

  return (
    <Stack direction={"row"}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <Box
              as="a"
              p="20px 17px"
              href={navItem.href ?? "#"}
              fontSize="18px"
              fontWeight={700}
              color={linkColor}
              _hover={{
                textDecoration: "none",
                color: linkHoverColor,
              }}
            >
              {navItem.label}
            </Box>
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={onToggle}>
      <Box
        py={2}
        as="a"
        href={href ?? "#"}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
      </Box>
    </Stack>
  );
};

interface NavItem {
  label: string;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Home",
    href: "https://talently.com/",
  },
  {
    label: "About Us",
    href: "https://talently.com/about-us",
  },
  {
    label: "Services",
    href: "https://talently.com/services",
  },
  {
    label: "Jobs",
    href: "https://careers.talently.com/",
  },
  {
    label: "Contact Us",
    href: "https://talently.com/contact",
  },
];
