import { createSlice } from '@reduxjs/toolkit';
import authService from 'services/auth.service';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';

export const candidatesSlice = createSlice({
  name: 'clients',
  initialState: {
    clientNav: 'all-clients',
    clientList: [], //for searching (async select)
    MyClients: [],
    clientData: {},
    client_location: [],
    client_id: null,
    clientPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    clientPageBtn: {
      page: 1,
      take: 50,
      query: '',
      filters: [],
    },
    RawList: [],
    allClient: [],
    allClientPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    allClientPageBtn: {
      page: 1,
      take: 50,
      query: '',
      filters: [],
    },
  },
  reducers: {
    listClients: (state, { payload }) => ({ ...state, ...payload }),
    getClient: (state, { payload }) => ({ ...state, ...payload }),
    navClient: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { listClients, getClient, navClient } = candidatesSlice.actions;
export default candidatesSlice.reducer;

const clientApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    MyListClients: builder.mutation({
      query: (data) => ({
        url: '/clients/my-list',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listClients({ MyClients: data.data.data }));
          dispatch(
            listClients({
              clientPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
          authService.logout();
        }
      },
    }),
    ViewClient: builder.mutation({
      query: ({ data, id }) => ({
        url: `/clients/view/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getClient({ clientData: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    AllListClients: builder.mutation({
      query: (data) => ({
        url: '/clients/all',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listClients({ allClient: data.data.data }));
          dispatch(
            listClients({
              allClientPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    UpdateClient: builder.mutation({
      query: ({ data, id }) => ({
        url: `/clients/update/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ToggleActiveClient: builder.mutation({
      query: ({ data, id }) => ({
        url: `/clients/acivate/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ToggleMVPClient: builder.mutation({
      query: ({ data, id }) => ({
        url: `/clients/mvp/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    RawListClients: builder.mutation({
      query: (data) => ({
        url: '/clients/raw-list',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listClients({ RawList: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ClientLocation: builder.mutation({
      query: ({ id }) => ({
        url: `/clients/location/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listClients({ client_location: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ClientSearch: builder.mutation({
      query: (data) => ({
        url: `/clients/search`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listClients({ clientList: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useMyListClientsMutation,
  useViewClientMutation,
  useAllListClientsMutation,
  useUpdateClientMutation,
  useToggleActiveClientMutation,
  useToggleMVPClientMutation,
  useRawListClientsMutation,
  useClientLocationMutation,
  useClientSearchMutation,
} = clientApiSlice;
