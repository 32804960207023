import { Route, Routes } from 'react-router';
import JobApplicationPage from './job-application';
import JobDetailsPage from './job-details';
import JobListingPage from './job-listing';
import '../../../theme/fonts.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { SITE_KEY } from 'constants/values';

const CareersPage = () => {
  return (
    <Routes>
      <Route path="/" element={<JobListingPage />}></Route>

      <Route path="/job-details" element={<JobDetailsPage />}>
        <Route path=":jobId" element={<JobDetailsPage />} />
      </Route>

      <Route path="/job-application" element={
        <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
          <JobApplicationPage />
        </GoogleReCaptchaProvider>
      }>
        <Route path=":jobId" element={
          <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
            <JobApplicationPage />
          </GoogleReCaptchaProvider>
        } />
      </Route>

    </Routes>
  );
};

export default CareersPage;
