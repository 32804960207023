import moment from "moment";
import { Box, Container, Flex, Image, Stack, Text } from "@chakra-ui/react";

import LogoWhite from "assets/images/logo-white.png";

export default function Footer() {
  return (
    <Box
      bg="linear-gradient(90deg,#4900d9 0,#b100ff 100%)"
      color="white"
      fontFamily="Alata"
    >
      <Container
        as={Stack}
        maxW={["90%", "80%"]}
        py={["29px", "63px"]}
        pb={["50px", "63px"]}
        px="initial"
      >
        <Flex justifyContent="space-between" flexDirection={["column", "row"]}>
          <Box>
            <Image src={LogoWhite} width={["30%", "15%"]} />
            <Box fontSize={["13px", "16px"]} mt="10px">
              Newport Beach, CA 92660
            </Box>
            <Box fontSize={["11px", "14px"]} mt={["10px", "20px"]}>
              © {moment().year()} Talently, LLC
            </Box>
          </Box>
          <Flex flexDirection={["column", "row"]}>
            <Flex flexDir="column" minWidth="200px">
              <Box
                sx={{
                  my: ["16px", "8px"],
                  mt: ["40px", "initial"],
                  fontSize: ["16px", "20px"],
                  fontWeight: 700,
                }}
              >
                Our Work
              </Box>
              {NAV_ITEMS.map((navItem) => (
                <Box
                  as="a"
                  href={navItem.href}
                  textDecoration="underline"
                  fontSize={["15px", "16px"]}
                  mt="5px"
                >
                  {navItem.label}
                </Box>
              ))}
            </Flex>
            <Flex flexDir="column" minWidth="200px">
              <Box
                sx={{
                  my: ["16px", "8px"],
                  mt: ["40px", "initial"],
                  fontSize: ["16px", "20px"],
                  fontWeight: 700,
                }}
              >
                Follow
              </Box>
              <Box
                as="a"
                href="https://www.linkedin.com/company/talently-recruiting/"
                textDecoration="underline"
                fontSize={["15px", "16px"]}
              >
                LinkedIn
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}

interface NavItem {
  label: string;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "About Us",
    href: "https://talently.com/about-us",
  },
  {
    label: "Services",
    href: "https://talently.com/services",
  },
  {
    label: "Jobs",
    href: "https://careers.talently.com/",
  },
];
