import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../../../../assets/css/autocomplete.css";
import { useSearchGeoTypesMutation } from "store/geo.slice";
import { number } from "yup";
import AtsAutoComplete from "./Autocomplete";

interface fieldInterface {
  name: string;
  value: any;
  errors?: any;
  touched?: any;
}

interface addCompInt {
  index?: number;
  handleChange: any;
  setFieldValue: any;
  cityField?: fieldInterface;
  zipField?: fieldInterface;
}

const AddressComponent = ({
  index = 0,
  setFieldValue,
  cityField,
  zipField,
}: addCompInt) => {
  const { geo_location } = useSelector((state: any) => state.geolocation);

  const [itemLocation, setItemLocation] = useState([]);
  const [reqGeo, resGeo] = useSearchGeoTypesMutation();
  const [currentName, setName] = useState("");

  useEffect(() => {
    setGeoItem();
  }, [geo_location, currentName]);

  const setGeoItem = () => {
    if (geo_location.length > 0) {
      let optios: any = [];
      geo_location.map((item: any, key: number) => {
        const value = currentName == "city" ? item.city : item.postal_code;
        optios.push({
          city: item.city,
          postal_code: item.postal_code,
          value: value,
          label: geoFormat(item),
          id: number,
        });
      });
      setItemLocation(optios);
    } else {
      setItemLocation([]);
    }
  };

  const geoFormat = (item: any) => {
    let format: any = "";
    try {
      const cityB = currentName == "city" ? { fontWeight: "bold" } : {};
      const postB = currentName == "postal_code" ? { fontWeight: "bold" } : {};
      format = (
        <>
          <Flex gap="2px">
            <Text style={postB}>{item?.postal_code}</Text>
            <Text style={cityB}>{item?.city}</Text>
          </Flex>
        </>
      );
    } catch (e) {
      format = "Error formatting";
    }
    return format;
  };

  const geoLocationChange = debounce((e: any, column: string) => {
    const search = e.target.value;
    if (search.length > 3) {
      const data = {
        search,
        column,
      };
      reqGeo(data);
    }
  }, 500);

  const handleOnSelect = (item: any, input: string) => {
    setFieldValue(cityField.name, item.city);
    setFieldValue(zipField.name, item.postal_code);
  };

  return (
    <div className="row" key={`jobAdd-${index}`}>
      <Flex gap="32px" mb="34px">
        <FormControl isInvalid={Boolean(cityField.errors && cityField.touched)}>
          <FormLabel
            fontSize="14px"
            lineHeight="18px"
            color="#2B2D42"
            fontWeight={600}
          >
            City{" "}
            <Box as="span" color="#6930CA">
              *
            </Box>
          </FormLabel>
          <AtsAutoComplete
            name={cityField.name}
            placeholder="City"
            type="text"
            variant="outline"
            value={cityField.value}
            onChange={(e: any) => {
              setFieldValue(cityField.name, e.target.value);
            }}
            onFocus={() => setName("city")}
            options={itemLocation}
            onKeyUp={(e: any) => geoLocationChange(e, "city")}
            isLoading={resGeo.isLoading}
            onSelect={(item: any) => {
              setFieldValue(cityField.name, item.value);
              handleOnSelect(item, "city");
            }}
          />

          <FormErrorMessage>{String(cityField.errors)}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(zipField.errors && zipField.touched)}>
          <FormLabel
            fontSize="14px"
            lineHeight="18px"
            color="#2B2D42"
            fontWeight={600}
          >
            Zip Code{" "}
            <Box as="span" color="#6930CA">
              *
            </Box>
          </FormLabel>

          <AtsAutoComplete
            name={zipField.name}
            placeholder="Zip Code"
            type="text"
            variant="outline"
            value={zipField.value}
            onChange={(e: any) => {
              setFieldValue(zipField.name, e.target.value);
            }}
            onFocus={() => setName("postal_code")}
            options={itemLocation}
            onKeyUp={(e: any) => geoLocationChange(e, "postal_code")}
            isLoading={resGeo.isLoading}
            onSelect={(item: any) => {
              setFieldValue(zipField.name, item.value);
              handleOnSelect(item, "postal_code");
            }}
          />
          <FormErrorMessage>{String(zipField.errors)}</FormErrorMessage>
        </FormControl>
      </Flex>
    </div>
  );
};

export default AddressComponent;
