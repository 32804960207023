import { defineStyleConfig } from '@chakra-ui/styled-system';

export const Link = defineStyleConfig({
	baseStyle: {
		color: 'primary.800',
		fontWeight: 'bold',
		':focus:not(:focus-visible)': {
			shadow: 'none',
		},
		_disabled: { pointerEvents: 'not-allowed' },
	},

	sizes: {
		sm: {
			fontSize: 'sm',
		},
		md: {
			fontSize: 'md',
		},
		lg: {
			fontSize: 'lg',
		},
	},

	variants: {
		solid: {
			color: 'primary.800',
			_hover: {
				color: 'primary.800',
			},
			_active: {
				color: 'primary.800',
			},
		},
	},

	defaultProps: {
		size: 'md',
		variant: 'solid',
	},
});
